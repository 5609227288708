import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import Team from "../components/Common/Team";
import FunFactsArea from "../components/Common/FunFactsArea";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
// import FeedbackStyleThree from '../components/Common/FeedbackStyleThree';
// import Partner from '../components/MachineLearning/Partner';

import AboutImg from "../assets/images/agency-image/agency-about-img.jpg";

// Shape Images
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";

const About3 = () => (
    <Layout>
        <SEO title="About Us" />

        <Navbar />

        <PageBanner pageTitle="About Us" />

        <div className="agency-about-area ptb-80">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="agency-about-img">
                            <img src={AboutImg} alt="image" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="agency-about-content">
                            <span className="sub-title">About Us</span>
                            <h2>Cloud training for anyone who is interested in learning</h2>
                            <div className="bar"></div>
                            <p>Join the incredible 30 Days of Cloud series where experts uncover the Google Cloud Foundational Series for students with little to no experience in cloud computing and are an ideal foundation for diverse career paths.</p>
                            <p>Learn at your own pace. Complete the online program on your own terms and be a part of your Developer Student Club.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Connect</h3>
                                <p>Meet students interested in developer technologies at your university. All are welcome, including those with diverse backgrounds and different majors.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Learn</h3>
                                <p>Learn about a range of technical topics and gain new skills through hands-on workshops, events, talks, and project-building activities - both online and in-person.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Grow</h3>
                                <p>Apply new learnings to build great solutions for local problems. Advance your skills, career, and network. Give back to your community by helping others learn, too.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>

        <Team />

        {/*<FeedbackStyleThree />*/}

        <FunFactsArea />

        {/*<div className="pb-80">*/}
        {/*    <Partner />*/}
        {/*</div>*/}

        <Footer />
    </Layout>
)

export default About3;
